<template>
  <div class="app-container merch-card">
    <!-- 搜索列 -->
    <div class="fx">
      <el-form ref="queryParams" :model="queryParams" :inline="true">
        <el-form-item label="订单号：">
          <el-input
            v-model="queryParams.orderNumber"
            placeholder="请输入订单号"
            clearable
            type="number"
            @keyup.enter.native="handleCurrentChange"
          />
        </el-form-item>
        <el-form-item label="支付方式：">
          <el-select
            clearable
            v-model="queryParams.paymentMode"
            placeholder="请选择支付方式"
          >
            <el-option
              v-for="(item, index) in payResult"
              :key="index"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleCurrentChange"
            style="width: 80px"
            >查 询</el-button
          >
          <el-button @click="handleReset" style="width: 80px" plain
            >重 置</el-button
          >
        </el-form-item>
      </el-form>
      <div class="fx-1 text-right">
        <el-button type="primary" @click="downLoad" style="width: 80px"
          >导 出</el-button
        >
      </div>
    </div>
    <!-- 列表 -->
    <el-table
      :data="searchList"
      v-loading="loading"
      border
      style="width: 100%"
      height="300px"
      :header-cell-style="{ background: '#F2F3F5' }"
    >
      <el-table-column prop="orderNumber" label="订单号" align="center" />
      <el-table-column
        prop="tradingType"
        label="交易类型"
        align="center"
      />
      <el-table-column prop="companyName" label="单位名称" align="center" />
      <el-table-column prop="userType" label="会员类型" align="center" />
      <!-- <el-table-column prop="" label="收货人/联系电话" align="center">
        <template slot-scope="{ row }">
          <div class="text-left">
            <div>{{ row.userName }}</div>
            <div>{{ row.userPhone }}</div>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="transactionAmount"
        label="交易金额（元）"
        align="center"
      />
      <el-table-column prop="payModestatue" label="支付方式" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.payModestatue }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="tradingStatus" label="交易状态" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.tradingStatus | tradingStatus }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="tradingHour" label="交易时间" align="center" />
      <el-table-column prop="orderSource" label="订单来源" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.orderSource | orderSource }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="footer contense">
      <div class="huitxt">
        <span>应收金额：¥{{ itemds.amountReceivable || "0" }}</span>
        <span class="marle_20">
          退款金额：¥{{ itemds.refundAmount || "0" }}</span
        >
        <span class="marle_20">
          账单金额：
          <span class="col_F34444"> ¥{{ itemds.billAmount || "0" }} </span>
        </span>
      </div>
      <div class="fx-1 text-right">
        <pagination
          :total="total"
          :page.sync="queryParams.currPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getSearchList"
        />
      </div>
    </div>
    <div class="firmBasics">收款信息</div>
    <!-- 收款信息 -->
    <el-table
      :data="PaymentinList"
      v-loading="loading"
      border
      style="width: 100%"
      height="250px"
      :header-cell-style="{ background: '#F2F3F5' }"
    >
      <el-table-column
        prop="collectionSerialNumber"
        label="收款流水"
        align="center"
      />
      <el-table-column prop="amount" label="收款金额（元）" align="center">
        <template slot-scope="{ row }">
          <div v-if="row.amount">￥{{ row.amount }}</div>
          <div v-else>—</div>
        </template>
      </el-table-column>
      <el-table-column prop="accountNumber" label="收款账户" align="center" />
      <el-table-column
        prop="remark"
        label="备注"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column prop="transferVoucher" label="转账凭证" align="center">
        <template slot-scope="{ row }">
          <el-image
            class="min-image"
            v-if="row.transferVoucher.length > 0"
            :src="row.transferVoucher[0]"
            :preview-src-list="[row.transferVoucher[0]]"
          >
          </el-image>
          <div v-else slot="placeholder" class="image-slot">
            暂无图片<span class="dot">...</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="登记时间" align="center">
        <template slot-scope="{ row }">
          {{ formatDate(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="creator" label="登记人" align="center" />
    </el-table>
  </div>
</template>

<script>
import UpimgList from "@/components/Updata/UpimgList.vue";
import { createNamespacedHelpers } from "vuex";
import { downLoadZip } from "@/utils/zipdownload";
const billList = createNamespacedHelpers("billList");
export default {
  name: "",
  components: {
    UpimgList,
  },
  data() {
    return {
      loading: false,
      total: 0,
      //列表搜索
      queryParams: {
        currPage: 1,
        pageSize: 10,
        billNumber: "", //账单单号
        orderNumber: "", //订单号
        paymentMode: "", //支付方式
      },
      itemds: {
        amountReceivable: "", //应收金额
        refundAmount: "", //退款金额
        billAmount: "", //账单金额
      },
      payResult: [], //支付方式
      searchList: [], //账单列表
      PaymentinList: [], //收款信息列表
    };
  },
  filters: {
    tradingStatus(v) {
      if (v >= 0 && v < 20) {
        return "待付款";
      }
      if (v >= 20 && v < 45) {
        return "待发货";
      }
      if (v >= 45 && v < 60) {
        return "待收货";
      }
      if (v == 60) {
        return "已收货";
      }
      if (v == 70) {
        return "已完成";
      }
      if (v == -2) {
        return "已取消";
      }
      if (v == -1) {
        return "已取消";
      }
    },
    orderSource(v) {
      switch (v) {
        case 0:
          return "PC";
        case 10:
          return "APP";
        case 20:
          return "小程序";
        default:
          return "——";
      }
    },
  },
  async created() {
    this.queryParams.billNumber = this.$route.query.billNumber;
    await this.getTypeList();
    await this.getSearchList();
  },

  methods: {
    ...billList.mapActions([
      "billDetailsList", //收款明细列表
      "billAmount", //收款明细金额相关
      "getPaymentTypeList", //获取支付方式
      "collectionInformation", //账单收款信息
    ]),
    downLoad() {
      downLoadZip(
        `/mgengine/import/obtainPurchaseOrderAndGoodsInfo/${this.queryParams.billNumber}`,
        "xlsx",
        "账单明细",
        {},
        "app",
        "get"
      );
    },
    //获取支付方式
    async getTypeList() {
      let data = await this.getPaymentTypeList();
      this.payResult = data.data;
    },
    async getSearchList() {
      await this.getcollection();
      await this.getList();
      const requestId = Date.now(); // 生成一个唯一请求ID
      this.lastRequestId = requestId; // 记录最后一次请求ID
      try {
        const response = await this.billAmount({
          billNumber: this.queryParams.billNumber,
        }); // 发起接口请求
        // 只保存最后一次请求的数据
        if (requestId === this.lastRequestId) {
          this.lastResponse = response;
          // 更新页面数据或进行其他操作
          this.getItem(this.lastResponse);
        }
      } catch (error) {
        // 处理请求错误
        // console.error("请求错误:", error);
      }
    },
    //获取列表数据
    async getList() {
      this.loading = true;
      this.billDetailsList(this.queryParams).then((res) => {
        this.loading = false;
        this.searchList = res.content.list;
        this.total = res.content.totalRecords;
        this.searchList.forEach((item) => {
          let payModestatue = this.payResult.find(
            (v) => v.code == item.paymentMode
          ).name;
          this.$set(item, "payModestatue", payModestatue);
        });
      });
    },
    //获取收款信息
    getcollection() {
      this.collectionInformation({
        billNumber: this.queryParams.billNumber,
      }).then((res) => {
        this.PaymentinList = res.content;
        this.PaymentinList.forEach((data) => {
          if (data.transferVoucher) {
            data.transferVoucher = [data.transferVoucher];
          }
        });
      });
    },
    //获取收款明细金额相关
    getItem(response) {
      if (response.content) {
        Object.assign(this.itemds, response.content);
      }
    },
    //搜索
    handleCurrentChange() {
      this.queryParams.currPage = 1;
      this.getList();
    },
    //重置
    handleReset() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.queryParams.billNumber = this.$route.query.billNumber;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  .merch-table {
    margin-top: 1%;
    width: 100%;
    height: 77%;
  }
  .userBtn {
    display: flex;
    justify-content: center;
    div {
      margin-left: 5px;
      color: #1abca9;
      cursor: pointer;
    }
  }
}
</style>
